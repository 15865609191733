import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const menuLinks = [
  'Pagrindinis', 'Paslaugos', 'Projektai', 'Edukacija', 'Savanoryste', 'Galerija', 'Kontaktai'
]
const Menu = ({ onToggleMenu }) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        {menuLinks.map((link, index) => (
          <li key={link}>
            <Link onClick={onToggleMenu} to={index === 0 ? '/' : `${link.toLowerCase()}`}>{link}</Link>
          </li>
        )) }
      </ul>
      {/*<ul className="actions vertical">*/}
      {/*    <li><a href="#" className="button special fit">Get Started</a></li>*/}
      {/*    <li><a href="#" className="button fit">Log In</a></li>*/}
      {/*</ul>*/}
    </div>
    <div className="close" onClick={onToggleMenu} />
  </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
