import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = ({ onToggleMenu }) => (
  <header id="header" className="alt">
    <Link to="/" className="logo"><strong>Krašto Pažinimas</strong></Link>
    <nav>
      <div className="menu-link" onClick={onToggleMenu} />
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func
}

export default Header
